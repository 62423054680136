<template>
  <nav>
    <v-app-bar flat app>
      <v-icon style="padding: 4px" class="nav-bar-icon" @click="drawer = !drawer">mdi-order-bool-descending
      </v-icon>
      <div class="ms-16">
        <v-icon style="padding: 4px" class="nav-bar-icon">mdi-wifi-marker
        </v-icon>
        <span class="ms-2 pt-8">{{ wifi_ip }}</span>
      </div>
      <v-spacer></v-spacer>
      <!-- ########################################################################################### -->
      <div class="select-lange" @click="toggleDropdownNoti">
        <div class="icon--flag">
          <v-badge bordered :color="qty_notification ? 'error' : ''" :content="qty_notification" overlap>
            <v-icon style="padding: 4px" class="nav-bar-icon">mdi-bell</v-icon>
          </v-badge>
        </div>
        <div class="container-select-language" :class="{ active: isToggleActiveNoti, hide: isToggleHideNoti }"
          v-if="qty_notification">
          <div :class="{ 'drop--down-noti': isToggleActiveNoti }" id="select--language">
            <div class="card--selected--language">
              <div class="language--flag">

                <noti_employee_contract_expire :employeeData="employeeEndContractData" />

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ########################################################################################### -->
      <div class="select-lange" @click="toggleDropdown">
        <div class="icon--flag">
          <i class="fal fa-cog"></i>
        </div>
        <div class="container-select-language" :class="{ active: isToggleActive, hide: isToggleHide }">
          <div :class="{ 'drop--down-language': isToggleActive }" id="select--language">
            <div class="card--selected--language">
              <div class="language--flag">
                <div class="flag--section">
                  <p class="text-center user-name">{{ userInfo.name }}</p>
                  <p class="text-center user-email">{{ userInfo.email }}</p>
                </div>
                <div class="flag--section">
                  <div class="header---section">
                    {{ $t("Dashboard.menu.language") }}
                    <div class="content---section">
                      <ul>
                        <li v-for="locale in locales" :key="locale" @click="switchLocales(locale)">
                          <span class="icon--section">
                            <i class="far fa-language"></i>
                          </span>
                          <span class="section--title">
                            {{ localeNames[locale] }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="flag--section">
                  <div class="header---section">
                    {{ $t("Dashboard.menu.setting") }}
                    <div class="content---section">
                      <ul>
                        <li class="mt-2" @click="CompanySetting">
                          <span class="icon--section">
                            <i class="far fa-cogs"></i>
                          </span>
                          <span class="section--title">
                            {{ $t("Dashboard.menu.company_setting") }}
                          </span>
                        </li>
                        <li class="mt-2" @click="changeNewPassword">
                          <span class="icon--section">
                            <i class="far fa-lock-alt"></i>
                          </span>
                          <span class="section--title">
                            {{ $t("Dashboard.menu.changePassword") }}
                          </span>
                        </li>
                        <li class="mt-2" @click="logoutUsers">
                          <span class="icon--section">
                            <i class="far fa-power-off icon--sign-out"></i>
                          </span>
                          <span class="section--title">
                            {{ $t("Dashboard.menu.sign out") }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ########################################################################################### -->
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" class="sidebar">
      <div class="sidebar-content" style="position: relative;height: 100% !important;">
        <vs-sidebar class="sidebar-scroll" absolute v-model="active" open not-shadow>
          <div class="image-logo" fixed-header>
            <img src="@/assets/logo.jpeg" alt="" />
          </div>
          <!--            Route for Synergy Admin-->
          <div v-if="$can(['superadmin', 'synergyadmin', 'synergyuser'])" @click="dashBoard">
            <vs-sidebar-item id="dashboard">
              <template #icon>
                <i class="fas fa-home"></i>
              </template>
              {{ $t("Navbar.dashboard") }}
            </vs-sidebar-item>
          </div>
          <div v-if="$can(['superadmin', 'synergyadmin', 'synergyuser'])">
            <!-- ############################################################### -->
            <vs-sidebar-group v-for="item in group_manage_user" :key="item.id">
              <template #header>
                <vs-sidebar-item :id="item.id" arrow class="text-header-dashboard" v-if="permission(item.permissions)">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  <h2>{{ $t(item.name) }}</h2>
                </vs-sidebar-item>
              </template>
              <div v-for="manage_user in item.route_group" @click="nextRouter(manage_user.route)" :key="manage_user.id">
                <vs-sidebar-item :id="manage_user.id" v-if="permission(item.permissions)">
                  <template #icon>
                    <i :class="manage_user.icon"></i>
                  </template>
                  {{ $t(manage_user.name) }}
                </vs-sidebar-item>
              </div>
            </vs-sidebar-group>

            <!-- ############################################################### -->
            <div v-for="(item, index) in routeSynergyAdmin" :key="index">
              <div @click="nextRouter(item.route)">
                <vs-sidebar-item :id="item.id" v-if="permission(item.permissions)">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  {{ $t(item.name) }}
                </vs-sidebar-item>
              </div>
            </div>
          </div>
          <!--Route for Company -->
          <div v-if="$can(['companyadmin', 'companyuser'])" @click="CompanyDashBoard">
            <!-- ############################################################### -->
            <vs-sidebar-item id="dashboard">
              <template #icon>
                <i class="fas fa-home"></i>
              </template>
              {{ $t("Navbar.dashboard") }}
            </vs-sidebar-item>
          </div>
          <!-- ############################################################### -->
          <div v-if="$can(['companyadmin', 'companyuser'])" @click="CompanyProfile">
            <vs-sidebar-item id="companyProfile">
              <template #icon>
                <i class="fal fa-building"></i>
              </template>
              {{ $t("Navbar.profile") }}
            </vs-sidebar-item>
          </div>
          <!-- ############################################################### -->
          <div v-if="$can(['companyadmin', 'companyuser'])">
            <vs-sidebar-group v-for="item in group_manage_company_data" :key="item.id">
              <template #header>
                <vs-sidebar-item v-if="permission(item.permissions)" :id="item.id" arrow class="text-header-dashboard">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  <h2>{{ $t(item.name) }}</h2>
                </vs-sidebar-item>
              </template>
              <div v-for="manage_employee in item.route_group" @click="nextRouter(manage_employee.route)"
                :key="manage_employee.id">
                <vs-sidebar-item :id="manage_employee.id" v-if="permission(item.permissions)">
                  <template #icon>
                    <i :class="manage_employee.icon"></i>
                  </template>
                  {{ $t(manage_employee.name) }}
                </vs-sidebar-item>
              </div>
            </vs-sidebar-group>
            <!-- ############################################################### -->
            <!-- <vs-sidebar-group
                  v-for="item in group_manage_holiday"
                  :key="item.id"
              >
                <template #header>
                  <vs-sidebar-item
                      v-if="permission(item.permissions)"
                      :id="item.id"
                      arrow
                      class="text-header-dashboard"
                  >
                    <template #icon>
                      <i :class="item.icon"></i>
                    </template>
                    <h2>{{ $t(item.name) }}</h2>
                  </vs-sidebar-item>
                </template>
                <div
                    v-for="manage_employee in item.route_group"
                    @click="nextRouter(manage_employee.route)"
                    :key="manage_employee.id"
                >
                  <vs-sidebar-item :id="manage_employee.id">
                    <template #icon>
                      <i :class="manage_employee.icon"></i>
                    </template>
                    {{ $t(manage_employee.name) }}
                  </vs-sidebar-item>
                </div>
              </vs-sidebar-group> -->
            <!-- ############################################################### -->
            <vs-sidebar-group v-for="item in group_manage_employee" :key="item.id">
              <template #header>
                <vs-sidebar-item v-if="permission(item.permissions)" :id="item.id" arrow class="text-header-dashboard">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  <h2>{{ $t(item.name) }}</h2>
                </vs-sidebar-item>
              </template>
              <div v-for="manage_employee in item.route_group" @click="nextRouter(manage_employee.route)"
                :key="manage_employee.id">
                <vs-sidebar-item :id="manage_employee.id" v-if="permission(manage_employee.permissions)">
                  <template #icon>
                    <i :class="manage_employee.icon"></i>
                  </template>
                  {{ $t(manage_employee.name) }}
                </vs-sidebar-item>
              </div>
            </vs-sidebar-group>

            <!-- ############################################################### -->
            <vs-sidebar-group v-for="item in group_employee_schedule" :key="item.id">
              <template #header>
                <vs-sidebar-item v-if="permission(item.permissions)" :id="item.id" arrow class="text-header-dashboard">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  <h2>{{ $t(item.name) }}</h2>
                </vs-sidebar-item>
              </template>
              <div v-for="manage_schedule in item.route_group" @click="nextRouter(manage_schedule.route)"
                :key="manage_schedule.id">
                <vs-sidebar-item :id="manage_schedule.id" v-if="permission(manage_schedule.permissions)">
                  <template #icon>
                    <i :class="manage_schedule.icon"></i>
                  </template>
                  {{ $t(manage_schedule.name) }}
                </vs-sidebar-item>
              </div>
            </vs-sidebar-group>

            <!-- ############################################################### -->
            <div v-for="(item, index) in routeCompany" :key="index">
              <div @click="nextRouter(item.route)">
                <vs-sidebar-item :id="item.id" v-if="permission(item.permissions)">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  {{ $t(item.name) }}
                </vs-sidebar-item>
              </div>
            </div>
            <!-- ############################################################### -->
          </div>
          <div v-if="$can(['companyadmin', 'companyuser'])">
            <!-- ############################################################### -->
            <vs-sidebar-group v-for="item in group_manage_salary" :key="item.id">
              <template #header>
                <vs-sidebar-item v-if="permission(item.permissions)" :id="item.id" arrow class="text-header-dashboard">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  <h2>{{ $t(item.name) }}</h2>
                </vs-sidebar-item>
              </template>
              <div v-for="manage_salary in item.route_group" @click="nextRouter(manage_salary.route)"
                :key="manage_salary.id">
                <vs-sidebar-item :id="manage_salary.id" v-if="permission(manage_salary.permissions)">
                  <template #icon>
                    <i :class="manage_salary.icon"></i>
                  </template>
                  {{ $t(manage_salary.name) }}
                </vs-sidebar-item>
              </div>
            </vs-sidebar-group>
            <!-- ############################################################### -->
            <vs-sidebar-group v-for="item in group_activity" :key="item.id">
              <template #header>
                <vs-sidebar-item v-if="permission(item.permissions)" :id="item.id" arrow class="text-header-dashboard">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  <h2>{{ $t(item.name) }}</h2>
                </vs-sidebar-item>
              </template>
              <div v-for="manage_activity in item.route_group" @click="nextRouter(manage_activity.route)"
                :key="manage_activity.id">
                <vs-sidebar-item :id="manage_activity.id" v-if="permission(manage_activity.permissions)">
                  <template #icon>
                    <i :class="manage_activity.icon"></i>
                  </template>
                  {{ $t(manage_activity.name) }}
                </vs-sidebar-item>
              </div>
            </vs-sidebar-group>
            <!-- ############################################################### -->
            <vs-sidebar-group v-for="item in group_report" :key="item.id">
              <template #header>
                <vs-sidebar-item v-if="permission(item.permissions)" :id="item.id" arrow class="text-header-dashboard">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  <h2>{{ $t(item.name) }}</h2>
                </vs-sidebar-item>
              </template>
              <div v-for="manage_report in item.route_group" @click="nextRouter(manage_report.route)"
                :key="manage_report.id">
                <vs-sidebar-item :id="manage_report.id" v-if="permission(manage_report.permissions)">
                  <template #icon>
                    <i :class="manage_report.icon"></i>
                  </template>
                  {{ $t(manage_report.name) }}
                </vs-sidebar-item>
              </div>
            </vs-sidebar-group>
            <!-- ############################################################### -->

            <div>
              <div @click="nextRouter('company.tracking-export-excel')">
                <vs-sidebar-item :id="'export'" v-if="permission('managecompanylog')">
                  <template #icon>
                    <i :class="'fal fa-file-excel'"></i>
                  </template>
                  Export Excel
                </vs-sidebar-item>
              </div>
            </div>

            <div v-for="(item, index) in routeLog" :key="index">
              <div @click="nextRouter(item.route)">
                <vs-sidebar-item :id="item.id" v-if="permission(item.permissions)">
                  <template #icon>
                    <i :class="item.icon"></i>
                  </template>
                  {{ $t(item.name) }}
                </vs-sidebar-item>
              </div>
            </div>

            <!-- ############################################################### -->
          </div>
        </vs-sidebar>
      </div>
      <modalChangePassword :visible="modalChangePassword" @change="(val) => (modalChangePassword = val)" />
    </v-navigation-drawer>
  </nav>
</template>
<script>
import { mapActions } from "vuex";
import modalChangePassword from "@/views/changePassword/modalChangePassword";
import noti_employee_contract_expire from '@/components/AppBar/Notifications/noti_employee_contract_expire'

export default {
  components: {
    modalChangePassword,
    noti_employee_contract_expire
  },
  data() {
    return {
      qty_notification: null,
      wifi_ip: "",
      userInfo: JSON.parse(localStorage.getItem("synergy_user")),
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      localeNames: { en: "EN", la: "LA" },
      isToggleActive: false,
      isToggleActiveNoti: false,
      isToggleHideNoti: true,
      isToggleHide: true,
      drawer: true,
      active: "",
      modalChangePassword: false,
      employeeEndContractData: [],
      //route for synergy
      routeSynergyAdmin: [
        {
          id: "company",
          name: "Navbar.company",
          icon: "fal fa-building",
          route: "company.index",
          permissions: ["managecompany"],
        },
        {
          id: "invoice",
          name: "Navbar.invoice",
          icon: "far fa-file-alt",
          route: "invoice.index",
          permissions: ["manageinvoice"],
        },

        {
          id: "businessType",
          name: "Navbar.business type",
          icon: "fal fa-fax",
          route: "businessType.index",
          permissions: ["managebusinesstype"],
        },
        {
          id: "bank",
          name: "Navbar.bank",
          icon: "fal fa-university",
          route: "bank.index",
          permissions: ["managebank"],
        },
        {
          id: "currency",
          name: "Navbar.currency",
          icon: "fal fa-usd-circle",
          route: "currency.index",
          permissions: ["managecurrency"],
        },

        {
          id: "package",
          name: "Navbar.package",
          icon: "fal fa-ballot",
          route: "package.index",
          permissions: ["managepackage"],
        },

        {
          id: "tax",
          name: "Navbar.tax",
          icon: "fal fa-file-invoice-dollar",
          route: "tax.index",
          permissions: ["managetax"],
        },

        {
          id: "socialSecurity",
          name: "Navbar.socialSecurity",
          icon: "fal fa-id-card-alt",
          route: "social_security.index",
          permissions: ["managesocialsecurity"],
        },
        {
          id: "activityLog",
          name: "Navbar.admin log",
          icon: "fal fa-clipboard-list-check",
          route: "admin.log",
          permissions: ["viewhistory"],
        },
      ],
      group_manage_user: [
        {
          id: "manage-user",
          icon: "fal fa-clipboard-user",
          name: "Navbar.manageUser",
          permissions: ["managesynergyuser"],
          route_group: [
            {
              id: "user-manage",
              name: "Navbar.userAdmin",
              icon: "fal fa-user-circle",
              route: "userAdmin.index",
              permissions: ["managesynergyuser"],
            },
            {
              id: "user-role",
              name: "Navbar.role",
              icon: "fal fa-user-tag",
              route: "role.index",
              permissions: ["managesynergyuser"],
            },
            {
              id: "user-permission",
              name: "Navbar.permission",
              icon: "fal fa-user-lock",
              route: "permission.index",
              permissions: ["managesynergyuser"],
            },
          ],
        },
      ],
      // Route Employee Schedule
      group_employee_schedule: [
        {
          id: "schedule-group",
          icon: "fal fa-alarm-clock",
          name: "Navbar.schedule",
          permissions: ["manageschedule"],
          route_group: [
            {
              id: "schedule",
              name: "groupSchedule.schedule.navbar",
              icon: "fal fa-code-commit",
              route: "schedule.index",
              permissions: ["manageschedule-schedule"],
            },
            {
              id: "employee-roster",
              name: "groupSchedule.roster.navbar",
              icon: "fal fa-code-commit",
              route: "employee.roster.index",
              permissions: ["manageschedule-roster"],
            },
            {
              id: "employee-ot",
              name: "employee_ot.navbar",
              icon: "fal fa-code-commit",
              route: "employee_ot.index",
              permissions: ["manageschedule-add_ot"],
            },
            {
              id: "employee-using-ot",
              name: "employee_using_ot.navbar",
              icon: "fal fa-code-commit",
              route: "employee_using_ot.index",
              permissions: ["manageschedule-add_ot"],
            },
          ],
        },
      ],
      //route for company admin
      routeCompany: [
        {
          id: "zone",
          name: "Navbar.zone",
          icon: "fal fa-map-marker-alt",
          route: "allZone.index",
          permissions: ["managezone"],
        },
        /* {
            id: "schedule",
            name: "Navbar.schedule",
            icon: "fal fa-alarm-clock",
            route: "schedule.index",
            permissions: ["manageschedule"],
          }, */
        {
          id: "companyUser",
          name: "Navbar.user",
          icon: "fal fa-users",
          route: "companyUser.index",
          permissions: ["managecompanyuser"],
        },
        {
          id: "authorize",
          name: "Navbar.authorizeDocument",
          icon: "fal fa-user-plus",
          route: "authorize.document.index",
          permissions: ["managedocument"],
        },
        {
          id: "document",
          name: "Navbar.document",
          icon: "fal fa-folder-open",
          route: "document.index",
          permissions: ["managedocument-in-out"],
        },
      ],

      routeLog: [
        {
          id: "Log",
          name: "Navbar.activity log",
          icon: "fal fa-clipboard-list-check",
          route: "company.log",
          permissions: ["managecompanylog"],
        },
      ],

      routeExportEcel: [
        {
          id: "export-excel",
          name: "Export Excel",
          icon: "fal fa-file-excel",
          route: "company.tracking-export-excel",
          permissions: ["managecompanylog"],
        },
      ],
      group_manage_company_data: [
        {
          id: "manage-data",
          icon: "fal fa-database",
          name: "Navbar.ManageComdata",
          permissions: ["managegeneral"],
          route_group: [
            {
              id: "job-title",
              name: "Navbar.jobTitle",
              icon: "fal fa-code-commit",
              route: "job.title.index",
              permissions: ["managebranch"],
            },
            {
              id: "education-level",
              name: "Navbar.educationLevel",
              icon: "fal fa-code-commit",
              route: "education.level.index",
              permissions: ["managebranch"],
            },
            {
              id: "workgin-year",
              name: "Navbar.workingYear",
              icon: "fal fa-code-commit",
              route: "working.year.index",
              permissions: ["managebranch"],
            },
            {
              id: "branch",
              name: "Navbar.branch",
              icon: "fal fa-code-commit",
              route: "branch.index",
              permissions: ["managebranch"],
            },
            {
              id: "department",
              name: "Navbar.department",
              icon: "fal fa-code-commit",
              route: "department.index",
              permissions: ["managedepartment"],
            },
            {
              id: "position",
              name: "Navbar.position",
              icon: "fal fa-code-commit",
              route: "position.index",
              permissions: ["manageposition"],
            },
            {
              id: "employee_level",
              name: "Navbar.employeeLevel",
              icon: "fal fa-code-commit",
              route: "employeeLevel.index",
              permissions: ["manageemployeelevel"],
            },
            {
              id: "benefit",
              name: "Navbar.benefit",
              icon: "fal fa-file-invoice-dollar",
              route: "benefit.index",
              permissions: ["managebenefit"],
            },
            {
              id: "ot",
              name: "Navbar.ot",
              icon: "fal fa-file-invoice-dollar",
              route: "setting_ot.index",
              permissions: ["managegeneral-setting_ot"],
            },
            {
              id: "late",
              name: "Navbar.late",
              icon: "fal fa-file-invoice-dollar",
              route: "late.index",
              permissions: ["managelate"],
            },
            {
              id: "absence",
              name: "Navbar.absence",
              icon: "fal fa-file-invoice-dollar",
              route: "absence.index",
              permissions: ["manageabsence"],
            },
            {
              id: "deduction",
              name: "Navbar.deduction",
              icon: "fal fa-code-commit",
              route: "deduction.index",
              permissions: ["managededuction"],
            },
            {
              id: "rest",
              name: "Navbar.rest day",
              icon: "fal fa-code-commit",
              route: "restDay.index",
              permissions: ["manageholiday"],
            },
            /* {
                id: "holidayType",
                name: "Navbar.holidayType",
                icon: "fal fa-code-commit",
                route: "holiday-type.index",
                permissions: ["managevacationtype"],
              }, */
            {
              id: "vacation",
              name: "Navbar.vacation",
              icon: "fal fa-code-commit",
              route: "vacation.index",
              permissions: ["managevacation"],
            },
          ],
        },
      ],
      group_manage_employee: [
        {
          id: "manage-employee",
          icon: "fal fa-clipboard-user",
          name: "Navbar.ManageEmployee",
          permissions: ["manageemployee"],
          route_group: [
            {
              id: "employee",
              name: "Navbar.employee",
              icon: "fal fa-code-commit",
              route: "employee.index",
              permissions: ["manageemployee-employee"],
            },
            {
              id: "empContract",
              name: "Navbar.empContract",
              icon: "fal fa-code-commit",
              route: "employee_contract.index",
              permissions: ["manageemployee-employee"],
            },
            {
              id: "resolve-employee",
              name: "Navbar.resolveEmployee",
              icon: "fal fa-code-commit",
              route: "employee.importData",
              permissions: ["manageemployee-employee"],
            },
            {
              id: "empHoliday",
              name: "Navbar.EmpHoliday",
              icon: "fal fa-code-commit",
              route: "emp.leave.index",
              permissions: ["manageemployee-holiday"],
            },
            {
              id: "scan-point",
              name: "Navbar.scan_point",
              icon: "fal fa-code-commit",
              route: "scan_point.index",
              permissions: ["manageemployee-scan_point"],
            },
            {
              id: "scan-time",
              name: "Navbar.scan_time",
              icon: "fal fa-code-commit",
              route: "scan_time.index",
              permissions: ["manageemployee-scan_time"],
            },
            {
              id: "scan-camera",
              name: "Navbar.scan_camera",
              icon: "fal fa-code-commit",
              route: "scan_camera.index",
              permissions: ["manageemployee-scan_camera"],
            },
            {
              id: "change-password",
              name: "Navbar.verify_code",
              icon: "fal fa-code-commit",
              route: "verify-code.index",
              permissions: ["manageemployee-verify_code"],
            },
            {
              id: "verify-code",
              name: "Navbar.change_password",
              icon: "fal fa-code-commit",
              route: "change.password.index",
              permissions: ["manageemployee-verify_code"],
            },
          ],
        },
      ],
      group_manage_salary: [
        {
          id: "manage-salary",
          icon: "fal fa-cash-register",
          name: "Navbar.ManageSalary",
          permissions: ["managesalary"],
          route_group: [
            {
              id: "salary",
              name: "Navbar.salary",
              icon: "fal fa-file-invoice-dollar",
              route: "payrolls-item.index",
              permissions: ["managesalary_calculate"],
            },
            {
              id: "adjust_salary",
              name: "Navbar.adjustment",
              icon: "fal fa-file-invoice-dollar",
              route: "adjustment-item.index",
              permissions: ["managesalary_calculate"],
            },
            /* {
                id: "exchange",
                name: "Navbar.exchange",
                icon: "far fa-sync",
                route: "exchange.index",
                permissions: ["manageexchange"],
              }, */
          ],
        },
      ],
      group_manage_holiday: [
        {
          id: "holiday-group",
          icon: "fal fa-calendar-alt",
          name: "Navbar.manageHoliday",
          permissions: ["manageholiday"],
          route_group: [],
        },
      ],
      group_activity: [
        {
          id: "activity",
          icon: "far fa-american-sign-language-interpreting",
          name: "Navbar.activity",
          permissions: ["manageactivity"],
          route_group: [
            {
              id: "anouncement",
              name: "activity.anouncement.navbar",
              icon: "fal fa-code-commit",
              route: "anouncement.index",
              permissions: ["manageactivity-anouncement"],
            },
            {
              id: "metting",
              name: "activity.metting.navbar",
              icon: "fal fa-code-commit",
              route: "meeting.index",
              permissions: ["manageactivity-metting"],
            },
          ],
        },
      ],
      group_report: [
        {
          id: "Report",
          icon: "far fa-file-chart-line",
          name: "Navbar.report",
          permissions: ["managereport"],
          route_group: [
            {
              id: "deviceScan",
              name: "report.deviceScan",
              icon: "fal fa-code-commit",
              route: "deviceScan.index",
              permissions: ["managereport-devicescan"],
            },
            {
              id: "fake_gps",
              name: "report.report-fake-gps",
              icon: "fal fa-code-commit",
              route: "report.fake_gps",
              permissions: ["managereport-devicescan"],
            },
            {
              id: "employee_clockin",
              name: "report.employee_clockin",
              icon: "fal fa-code-commit",
              route: "clock_in_out.index",
              permissions: ["managereport-absence"],
            },
            {
              id: "employee_clockin_correction",
              name: "report.employee_clockin_correction",
              icon: "fal fa-code-commit",
              route: "clock_in_out_correction.index",
              permissions: ["managereport-absence"],
            },
            {
              id: "employee_clockin_outside",
              name: "report.employee_clockin_outside",
              icon: "fal fa-code-commit",
              route: "clock_in_out_outside.index",
              permissions: ["managereport-absence"],
            },
            {
              id: "sso",
              name: "report.sso",
              icon: "fal fa-code-commit",
              route: "ssoReport.index",
              permissions: ["managereport-sso"],
            },
            {
              id: "employee-tax",
              name: "report.emTaxeport",
              icon: "fal fa-code-commit",
              route: "emTaxeport.index",
              permissions: ["managereport-tax"],
            },
            {
              id: "exportTodolist",
              name: "report.todolist",
              icon: "fal fa-code-commit",
              route: "todolist.index",
              permissions: ["managereport-todolist"],
            },
            {
              id: "E-approval",
              name: "report.e_approval",
              icon: "fal fa-code-commit",
              route: "approval.index",
              permissions: ["managereport-e_approval"],
            },
            {
              id: "E-Leave",
              name: "report.e_leave",
              icon: "fal fa-code-commit",
              route: "e-leave.index",
              permissions: ["managereport-e_leave"],
            },
            {
              id: "public_Leave",
              name: "report.public_leave",
              icon: "fal fa-code-commit",
              route: "e-leave.public.index",
              permissions: ["managereport-e_leave"],
            },
            {
              id: "follow-schedule",
              name: "follow_schedule.nav",
              icon: "fal fa-code-commit",
              route: "follow_schedule.index",
              permissions: ["managereport-follow_schedule"],
            },
            {
              id: "employee-ot_list",
              name: "info_ot.navbar",
              icon: "fal fa-code-commit",
              route: "employee_ot_list.index",
              permissions: ["manageschedule-info_ot"],
            },
          ],
        },
      ],
    };
  },
  methods: {
    fetchAppBarData() {
      this.$axios.get(`company/app-bar/data`).then((res) => {
        if (res.data.code === 200) {
          this.employeeEndContractData = res.data.data.employee_end_contract;
          this.qty_notification = res.data.data.employee_end_contract.length;
          this.wifi_ip = res.data.data.wifi_ip;
        }
      })
    },

    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    ReportAbsence() {
      this.$router
        .push({
          name: "absenceReport.index",
        })
        .catch(() => { });
    },
    ReportEmpTax() {
      this.$router
        .push({
          name: "emTaxeport.index",
        })
        .catch(() => { });
    },
    permission(value) {
      const userRole = JSON.parse(localStorage.getItem("user_role"));
      /**
       *  tha user role pen superAdmin || CompanyAdmin man hai show all;
       */

      if (userRole === "superadmin" || userRole === "companyadmin") {
        return true;
      }
      if (value) {
        /**
         *
         * tha user me permission hai show menu in navbar;
         */
        const localPermission = JSON.parse(
          localStorage.getItem("userPermission")
        );
        return localPermission.some((el) => value.includes(el));
      }
    },

    nextRouter(route_name) {
      this.$router
        .push({
          name: route_name,
        })
        .catch(() => { });
    },
    dashBoard() {
      this.$router
        .push({
          name: "Dashboard",
        })
        .catch(() => { });
    },
    CompanyDashBoard() {
      this.$router
        .push({
          name: "CompanyDashboard",
        })
        .catch(() => { });
    },
    CompanyProfile() {
      this.$router.push({ name: "company.profile" }).catch(() => { });
    },
    CompanySetting() {
      this.$router.push({ name: "company.setting" }).catch(() => { });
    },
    logoutUsers() {
      this.LogoutUser().then((res) => {
        if (res) {
          setTimeout(() => {
            this.$emit("close");
            this.$emit("success");
            this.$notification.OpenNotification_Logout_Success(
              "top-right",
              "primary",
              3000
            );
          }, 300);
        }
      });
    },
    //function for call open dropdown in navbar
    toggleDropdown(e) {
      this.isToggleHideNoti = true;
      this.isToggleActiveNoti = false;
      e.stopPropagation();
      this.isToggleHide = false;
      this.isToggleActive = !this.isToggleActive;
      this.$nextTick(() => {
        setTimeout(() => {
          this.isToggleHide = !this.isToggleActive;
        }, 100);
      });
    },
    toggleDropdownNoti(e) {
      this.isToggleHide = true;
      this.isToggleActive = false;
      e.stopPropagation();
      this.isToggleHideNoti = false;
      this.isToggleActiveNoti = !this.isToggleActiveNoti;
      this.$nextTick(() => {
        setTimeout(() => {
          this.isToggleHideNoti = !this.isToggleActiveNoti;
        }, 100);
      });
    },
    //function for close dropdown
    closeDropDown() {
      this.isToggleHide = true;
      this.isToggleActive = false;
      //
      this.isToggleHideNoti = true;
      this.isToggleActiveNoti = false;
    },
    //function close drop down in navbar when we click body space empty
    registerOutsideClick() {
      document.body.addEventListener("click", this.closeDropDown);
    },
    destroyOutsideClick() {
      document.body.removeEventListener("click", this.closeDropDown);
    },
    //function for change language in web admin
    switchLocales(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        localStorage.setItem("Lang", locale);
      }
    },

    changeNewPassword() {
      this.modalChangePassword = true;
    },
  },
  mounted() {
    this.registerOutsideClick();
  },

  beforeDestroy() {
    this.destroyOutsideClick();
  },
  created() {
    this.fetchAppBarData();
  },
};
</script>
<style scoped lang="scss">
.user-name {
  margin: 0px;
}

.user-email {
  color: rgb(86, 85, 85);
  font-size: 14px;
}

p {
  word-break: break-all;
  white-space: normal;
}

// .con-nav {
//   height: 100% !important;
// }
div .vs-sidebar {
  height: 100% !important;
}

.branding {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.image-logo {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  background: #ffffff;

  img {
    width: 100px;
    height: 120px;
    margin-top: 8px;
    object-fit: contain;
  }

  h1 {
    text-transform: uppercase;
  }
}

.logout i {
  font-size: 24px;
  cursor: pointer;
  color: #e96464;
}

.select-lange {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  user-select: none;

  .icon--flag {
    font-size: 22px;
    margin-left: 20px;
    position: absolute;

    i {
      margin-right: 10px;
      color: #6e6d6d;
    }
  }
}

.container-select-language {
  width: 100%;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.hide {
    visibility: hidden;
  }

  .drop--down-language {
    margin: 20px 0;
    float: right;
    background-color: white;
    box-shadow: 10px 10px 28px 0 rgb(0 0 0 / 20%);
    width: 260px;
    position: absolute;
    border-radius: 8px;
    padding: 18px;
    transition: ease-out 0.2s;
    right: 0px;
  }

  .drop--down-noti {
    margin: 20px 0;
    float: right;
    background-color: white;
    box-shadow: 10px 10px 28px 0 rgb(0 0 0 / 20%);
    position: absolute;
    border-radius: 8px;
    padding: 18px;
    transition: ease-out 0.2s;
    right: 0px;
  }

  .card--selected--language {
    width: 100%;
    height: auto;
    // background-color: #18BBEC;
    display: flex;

    .language--flag {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      //  background-color: red;

      .flag--section {
        width: 100%;
        height: auto;
        padding: 4px;
        //  background-color: yellow;

        .header---section {
          width: 100%;
          height: 100%;
          font-family: $font-family;

          .content---section {
            width: 100%;
            height: auto;
            //   background-color: blue;

            ul {
              width: 100%;
              height: 100%;
              //  background-color: #18BBEC;
              margin: 8px;
              padding: 0;
              cursor: pointer;

              .icon--section {
                margin-right: 10px;
                color: #696868;
                font-weight: 400;
              }

              .section--title {
                color: #696868;
                text-transform: capitalize;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
  }
}

.drop--down-language:after {
  bottom: 100%;
  left: 89%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 10px solid transparent;
  border-bottom-color: white;
  margin-left: -10px;
}

.sidebar-content {
  width: 100%;
  height: 550px;
  overflow: scroll;
  overflow-x: hidden;

  .sidebar-scroll {
    width: 400px;
    margin-top: 0;
  }
}


.sidebar-content::-webkit-scrollbar {
  display: none;
  width: 8px;
}

.sidebar-content::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 30px;
  height: auto;
}

.sidebar-content::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}

//@media screen and(max-width: 1024px) {
//  .sidebar-content {
//    height: 80vh;
//    overflow: scroll;
//    overflow-x: hidden;
//  }
//}
//
//@media screen and(max-width: 768px) {
//  .sidebar-content {
//    height: 80vh;
//    overflow: scroll;
//    overflow-x: hidden;
//  }
//}</style>
