import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=5377654a&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=5377654a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5377654a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBadge,VIcon,VNavigationDrawer,VSpacer})
